import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { User } from "../models"
import { BehaviorSubject } from "rxjs";
import { map, } from "rxjs/operators";
import { AuthenticationService } from "../services"
@Injectable({ providedIn: "root" })
export class InvoicesService {

  private baseUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  areas: string[];
  access: string;
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string, private readonly authService: AuthenticationService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
    this.areas = this.currentUserSubject.value.areas;
    this.access = this.currentUserSubject.value.access;
    this.baseUrl = baseUrl;
  }
  getInvoices(): Observable<InvoiceData[]> {
    return this.http.get<GetInvoicesResponse>(`${this.baseUrl}api/Invoices/GetInvoices`)
      .pipe(map<GetInvoicesResponse, InvoiceData[]>(response => {
        return response.invoiceData;
      }));
  }

  isAdmin() {
    //return this.roles.includes("InvoiceAdmin");
    return this.access === "Site" || this.access === "Company";
  }

}


export class GetInvoicesResponse {
  error: string;
  invoiceData: InvoiceData[];
}


export class InvoiceData {
  invoiceNo: string;
  dueDate: Date;
  orderNo: string;
  name: string;
  value: string;
}
