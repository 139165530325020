import { Component, ViewChild, HostBinding, OnInit, Inject } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { StoreService, SalesOrderData, AlertService} from "../../../services";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";


@Component({
  selector: "quotes-selector",
  templateUrl: "quotes.component.html",
  styleUrls: ["./quotes.component.scss"],

})
export class QuotesComponent extends UpgradableComponent implements OnInit {

  @HostBinding("class.mdl-grid") private readonly mdlGrid = true;
  @HostBinding("class.mdl-cell") private readonly mdlCell = true;
  @HostBinding("class.mdl-cell--12-col-desktop") private readonly mdlCell12ColDesktop = true;
  @HostBinding("class.mdl-cell--12-col-tablet") private readonly mdlCell12ColTablet = true;
  @HostBinding("class.mdl-cell--4-col-phone") private readonly mdlCell4ColPhone = true;
  @HostBinding("class.mdl-cell--top") private readonly mdlCellTop = true;
  @HostBinding("class.ui-tables") private readonly uiTables = true;

  displayedColumns: string[] = ["reference", "status", "total", "lastUpdated", "delete"];
    quotesResponseDataSource = new MatTableDataSource<SalesOrderData>();
  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    this.quotesResponseDataSource.paginator = matPaginator;
  }
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.quotesResponseDataSource.sort = matSort;
  }

  loading = false;
  noQuotes = false;

  public constructor(private readonly tablesService: StoreService,
    private readonly router: Router,
    private alertService: AlertService,
    public dialog: MatDialog) {
    super();
    this.loading = true;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.quotesResponseDataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {

    this.tablesService.getQuotes().subscribe(result => {
      if (result.length === 0) {
        this.noQuotes = true;
      }
      this.quotesResponseDataSource = new MatTableDataSource<SalesOrderData>(result);
      this.loading = false;
    });

    componentHandler.upgradeAllRegistered();
  }

  ngOnInit(): void {

  }

  delete(event: MouseEvent, salesOrderId: number) {
    event.stopPropagation();
    this.openDialog(salesOrderId);
    //this.loading = true;
    //this.tablesService.delete(salesOrderId).pipe(first()).subscribe((data) => {
    //  if (data.error) {
    //    this.alertService.error(data.error);
    //  } else {
    //    this.alertService.error("Your quote has been deleted.");
    //  }
    //  this.loading = false;
    //}, error => {
    //  this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
    //  this.loading = false;
    //});
  }

  details(salesOrderId: number) {
    this.router.navigate(['app/orders/details/' + salesOrderId]);
  }

  openDialog(salesOrderId: number): void {
    const dialogRef = this.dialog.open(DeleteQuoteDialogComponent, {
      width: '380px',
      data: {  }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event === "Success") {
        this.loading = true;
        this.tablesService.delete(salesOrderId).pipe(first()).subscribe((data) => {
          if (data.error) {
            this.alertService.error(data.error);
          } else {
            this.ngAfterViewInit();
            this.alertService.error("Your quote has been deleted.");
          }
          this.loading = false;
        }, error => {
          this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
          this.loading = false;
        });
      }
    });
  }


  getDetails(row: SalesOrderData) {
    //this.router.navigate(['app/tickets/details/' + row.ticketNumber]);

    const url = this.router.serializeUrl(
      this.router.createUrlTree([`app/quotes/details/` + row.salesOrderId])
    );

    window.open(url, "_blank");

  }
  

}



@Component({
  selector: 'delete-quote-dialog',
  templateUrl: 'delete-quote-dialog.component.html',
})
export class DeleteQuoteDialogComponent implements OnInit {
  salesOrderId: number;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DeleteQuoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  form: FormGroup;

  onNoClick(): void {
    this.dialogRef.close({ event: 'Cancel' });

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      salesOrderId: [""]
    });
  }
  

  onSubmit() {
    
    this.dialogRef.close({ event: "Success", data: this.data });
  }


}
