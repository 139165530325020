import { Component, ViewChild, HostBinding } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { TicketsService, TicketData } from "../../../services";
import { Router } from "@angular/router";

@Component({
  selector: "awaiting-tickets-selector",
  templateUrl: "awaiting-tickets.component.html",
  styleUrls: ["./awaiting-tickets.component.scss"],
})
export class AwaitingTicketsComponent extends UpgradableComponent {
  @HostBinding("class.mdl-grid") private readonly mdlGrid = true;
  @HostBinding("class.mdl-cell") private readonly mdlCell = true;
  @HostBinding("class.mdl-cell--12-col-desktop") private readonly mdlCell12ColDesktop = true;
  @HostBinding("class.mdl-cell--12-col-tablet") private readonly mdlCell12ColTablet = true;
  @HostBinding("class.mdl-cell--4-col-phone") private readonly mdlCell4ColPhone = true;
  @HostBinding("class.mdl-cell--top") private readonly mdlCellTop = true;
  @HostBinding("class.ui-tables") private readonly uiTables = true;

  displayedColumns: string[] = ["ticketNumber", "subjectLine", "openDate", "status"];
  awaitingResponseDataSource = new MatTableDataSource<TicketData>();

  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    this.awaitingResponseDataSource.paginator = matPaginator;
  }
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.awaitingResponseDataSource.sort = matSort;
  }

  loading = false;
  noTickets = false;

  public constructor(private readonly tablesService: TicketsService,
    private readonly router: Router) {
    super();
    this.loading = true;
    if (this.tablesService.isAdmin()) {
      this.displayedColumns = ["ticketNumber", "subjectLine", "openDate", "caller", "location", "status"];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.awaitingResponseDataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {

    this.tablesService.getAwaitingTickets().subscribe(result => {
      if (result.length === 0) {
        this.noTickets = true;
      }
      this.awaitingResponseDataSource = new MatTableDataSource<TicketData>(result);
      this.loading = false;
    });
  }


  getDetails(row: TicketData) {
    this.router.navigate(["app/tickets/details/" + row.ticketNumber]);
  }
}
