import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TicketsService } from '../../../services';
import { forkJoin } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "../../../services";
import { Injectable, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
@Component({
  selector: 'app-dialog',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss']
})
export class AddAttachmentComponent implements OnInit {
  form2: FormGroup;
  fileToUpload: File = null;
  addingNote: boolean;
  fileExists: boolean;
  constructor(
    public dialogRef: MatDialogRef<AddAttachmentComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private service: TicketsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit(): void {
    this.form2 = this.formBuilder.group({
      attachmentDescription: ["", Validators.required]
    });
    this.addingNote = false;
    this.fileExists = false;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.fileExists = true;
  }

  uploadFileToActivity() {
    this.addingNote = true;

    this.service.postFile(this.fileToUpload, this.data.ticketId, this.form2.controls.attachmentDescription.value).subscribe(data => {

      if (data.error) {
        this.alertService.error("Something went wrong uploading your attachment.  Please try again later.");
      } else {
        this.alertService.success("Attachment added!");
        this.dialogRef.close();
      }
      this.addingNote = false;


    }, error => {
      this.alertService.error("Something went wrong uploading your attachment.  Please try again later.");
      this.addingNote = false;

      console.log(error);
    });
  }
}
