import { Component, ViewChild, HostBinding } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { InvoiceData, InvoicesService } from "../../services";


@Component({
  selector: "invoices",
  templateUrl: "invoices.component.html",
  styleUrls: ["invoices.component.scss"],
})
export class InvoicesComponent extends UpgradableComponent {
  @HostBinding("class.mdl-grid") private readonly mdlGrid = true;
  @HostBinding("class.mdl-cell") private readonly mdlCell = true;
  @HostBinding("class.mdl-cell--12-col-desktop") private readonly mdlCell12ColDesktop = true;
  @HostBinding("class.mdl-cell--12-col-tablet") private readonly mdlCell12ColTablet = true;
  @HostBinding("class.mdl-cell--4-col-phone") private readonly mdlCell4ColPhone = true;
  @HostBinding("class.mdl-cell--top") private readonly mdlCellTop = true;
  @HostBinding("class.ui-tables") private readonly uiTables = true;

  displayedColumns: string[] = ["invoiceNo", "dueDate", "orderNo", "name", "value"];
  openResponseDataSource = new MatTableDataSource<InvoiceData>();

  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    this.openResponseDataSource.paginator = matPaginator;
  }
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.openResponseDataSource.sort = matSort;
  }

  loading = false;
  noTickets = false;

  public constructor(private readonly tablesService: InvoicesService,
    private readonly router: Router) {
    super();
    this.loading = true;
    if (this.tablesService.isAdmin()) {
      this.displayedColumns = ["invoiceNo", "dueDate", "orderNo", "name", "value"];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.openResponseDataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {

    this.tablesService.getInvoices().subscribe(result => {
      if (result.length === 0) {
        this.noTickets = true;
      }
      this.openResponseDataSource = new MatTableDataSource<InvoiceData>(result);
      this.loading = false;
    });
  }


  getDetails(row: InvoiceData) {
    this.router.navigate(["app/invoices/details" + row.invoiceNo]);
  }
}
