import { Component } from "@angular/core";
import { AuthenticationService } from "../../services";
import { User } from "../../models"
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-common-layout",
  templateUrl: "./common-layout.component.html",
})
export class CommonLayoutComponent {
  form: FormGroup;
  private readonly currentUserSubject: BehaviorSubject<User>;
  user: string;


  constructor(private readonly authService: AuthenticationService, private readonly router: Router, private readonly formBuilder: FormBuilder,) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
    if (this.currentUserSubject.value !== null) {
      this.user = this.currentUserSubject.value.fullName;
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      ticket: ["", Validators.required]
    });
  }

  logout() {
    this.authService.logout();
  }

  //logoutAsgardeo() {
  //  this.authService.logoutAsgardeo;
  //}

  onSubmit() {
    this.router.navigate(["app/tickets/details/" + this.form.controls.ticket.value]);
  }
}
