import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { User } from "../models"
import { BehaviorSubject } from "rxjs";
import { map, } from "rxjs/operators";
import { AuthenticationService } from "../services"
import { SortDirection } from "@angular/material";
@Injectable({ providedIn: "root" })
export class TicketsService {

  private baseUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  areas: string[];
  access: string;
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string, private readonly authService: AuthenticationService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
    this.areas = this.currentUserSubject.value.areas;
    this.access = this.currentUserSubject.value.access;
    this.baseUrl = baseUrl;
  }
  getOpenTickets(): Observable<TicketData[]> {
    return this.http.get<GetOpenTicketsResponse>(`${this.baseUrl}api/Tickets/OpenTickets`)
      .pipe(map<GetOpenTicketsResponse, TicketData[]>(response => {
        return response.ticketData;
      }));
  }

  getAwaitingTickets(): Observable<TicketData[]> {
    return this.http.get<GetAwaitingTicketsResponse>(`${this.baseUrl}api/Tickets/AwaitingTickets`)
      .pipe(map<GetAwaitingTicketsResponse, TicketData[]>(response => {
        return response.ticketData;
      }));
  }

  getClosedTickets(): Observable<TicketData[]> {
    return this.http.get<GetClosedTicketsResponse>(`${this.baseUrl}api/Tickets/ClosedTickets`)
      .pipe(map<GetClosedTicketsResponse, TicketData[]>(response => {
        return response.ticketData;
      }));
  }  

  getClosedTicketsPaged(sort: string, order: SortDirection, page: number, pageSize: number, ticketNoFilter: string, subjectFilter: string, callerFilter: string, locationFilter: string): Observable<GetClosedTicketsResponse> {
    return this.http.get<GetClosedTicketsResponse>(`${this.baseUrl}api/Tickets/ClosedTickets?sort=${sort}&order=${order}&page=${page}&pageSize=${pageSize}&ticketNo=${ticketNoFilter}&subject=${subjectFilter}&caller=${callerFilter}&location=${locationFilter}`)
      .pipe(map<GetClosedTicketsResponse, GetClosedTicketsResponse>(response => {
        return response;
      }));
  }
  

  getTicketDetails(ticketNumber: string): Observable<GetTicketDetailsResponse> {
    return this.http.get<GetTicketDetailsResponse>(`${this.baseUrl}api/Tickets/TicketDetails?ticketNumber=` + ticketNumber)
      .pipe(map<GetTicketDetailsResponse, GetTicketDetailsResponse>(response => {
        return response;
      }));
  }

  addNote(ticketNumber: string, note: string): Observable<AddTicketNoteResponse> {
    return this.http.post<AddTicketNoteResponse>(`${this.baseUrl}api/Tickets/AddNote?ticketNumber`,
      { 'ticketNumber': ticketNumber, 'note': note })
      .pipe(map<AddTicketNoteResponse, AddTicketNoteResponse>(response => {
        return response;
      }));
  }


  isAdmin() {
    //todo - do we need to check this is right?
    return this.access === "Site" || this.access === "Company";
  }


  createTicket(subject: string, description: string[], category: string) {

    let categoryId = 1371;

    if (category === "hardware") {
      categoryId = 1349;
    }

    if (category === "connectivity") {
      categoryId = 1358;
    }

    if (category === "software") {
      categoryId = 1350;
    }

    if (category === "rma") {
      categoryId = 1375;
    }

    if (category === "deploymentNew") {
      categoryId = 1384;
    }

    if (category === "deploymentExisting") {
      categoryId = 1384;
    }

    if (category === "deploymentOther") {
      categoryId = 1384;
    }


    return this.http.post<CreateTicketResponse>(`${this.baseUrl}api/Tickets/CreateTicket?`,
      { 'categoryId': categoryId, 'subjectLine': subject, 'description': description.join(`\r\n`) })
      .pipe(map<CreateTicketResponse, CreateTicketResponse>(response => {
        return response;
      }));
  }

  public upload(
    files: Set<File>
  ): { [key: string]: { progress: Observable<number> } } {
    const status: { [key: string]: { progress: Observable<number> } } = {};

    files.forEach(file => {
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      const req = new HttpRequest('POST', `${this.baseUrl}api/Tickets/AddAttachment`, formData, {
        reportProgress: true
      });

      const progress = new Subject<number>();

      const startTime = new Date().getTime();
      this.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
        }
      });

      status[file.name] = {
        progress: progress.asObservable()
      };
    });

    return status;
  }

  postFile(fileToUpload: File, ticketNo: string, description: string): Observable<AddTicketAttachmentResponse> {
    const endpoint = `${this.baseUrl}api/Tickets/UploadAttachment`;
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    formData.append('ticketNo', ticketNo);
    formData.append('description', description);
    return this.http
      .post<AddTicketAttachmentResponse>(endpoint, formData)
      .pipe(map<AddTicketAttachmentResponse, AddTicketAttachmentResponse>(response => { return response; }));
  }

  downloadAttachment(attachmentId: string): Observable<HttpEvent<Blob>> {
    const endpoint = `${this.baseUrl}api/Tickets/GetAttachment?attachmentId=${attachmentId}`;

    return this.http.request(new HttpRequest(
      'GET',
      endpoint,
      null,
      {
        reportProgress: false,
        responseType: 'blob'
      }));
  }

}

export class AddTicketNoteResponse {
  error: string;
}


export class AddTicketAttachmentResponse {
  error: string;
}

export class GetOpenTicketsResponse {
  error: string;
  ticketData: TicketData[];
}

export class CreateTicketResponse {
  error: string;
  ticketId: BigInteger;
}


export class GetAwaitingTicketsResponse {
  error: string;
  ticketData: TicketData[];
}

export class GetClosedTicketsResponse {
  error: string;
  ticketData: TicketData[];
  totalCount: number;
}

export class GetTicketDetailsResponse {
  error: string;
  ticketDetails: TicketDetails;
}

export class TicketData {
  ticketNumber: string;
  subjectLine: string;
  openDate: Date;
  closedDate: Date;
  status: string;
  caller: string;
  location: string;
}

export class TicketDetails {
  ticketNumber: string;
  subjectLine: string;
  openDate: Date;
  lastModified: Date;
  callerName: string;
  category: string;
  group: string;
  status: string;
  priority: string;
  description: string;
  closeDate: Date;
  resolved: string;
  resolvedDate: Date;
  solution: string;
  ticketActions: TicketHistory[];
  ticketAttachments: TicketAttachment[];
}

export class TicketHistory {
  description: string;
  actionDate: Date;
  actionUserName: string;
  notes: string;
  isCustomerAction: boolean;
  actionUserEmail: string;
}

export class TicketAttachment {
  attachmentId: string;
  fileName: string;
  description: string;
  uploadDate: Date;
  attachedBy: string;
}
