import { Component, OnInit, HostBinding, Inject} from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { StoreService, AlertService, QuoteDetailsLineData, QuoteDetailsData } from '../../../services';
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { HttpEventType } from "@angular/common/http";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: "order-detail-selector",
  templateUrl: "order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],

})
export class OrderDetailComponent extends UpgradableComponent implements OnInit {
  details: QuoteDetailsData;
  lineItems: QuoteDetailsLineData[];
  loadingPage: boolean;
  loading: boolean;
  total: number;
  confirmed: boolean;
  customerPO: string;
  poFile: File = null;
  old: boolean = false;
  constructor(
    public storeService: StoreService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public dialog: MatDialog,
    private readonly router: Router) {
    super();
  }


  convert(poNumber: string, file: File) {

    //check PO number matches the regex
    if (this.details.poNumberRegex != null && this.details.poNumberRegex != "") {
      let regex = new RegExp(this.details.poNumberRegex);
      if (!regex.test(poNumber)) {
        this.alertService.error("PO Number Invalid - " + this.details.poNumberRegexDescription);
        return;
      }
    }

    //check file size > 3mb?
    if (file !== null && file.size > 3145728) {
      this.alertService.error("File too large");
      return;
    }

    this.loading = true;
    this.storeService.convertToOrder(this.details.salesOrderId, poNumber, file).pipe(first()).subscribe((data) => {
      if (data.error) {
        this.alertService.error(data.error);
      } else {
        this.alertService.error("Success! Your order has been placed.");
      }
      this.loading = false;
      this.ngOnInit();
    }, error => {
      this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
      this.loading = false;
    });
  }  

  download() {
    this.storeService.downloadQuote(this.details.salesOrderId).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.Response:
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = `${this.details.type}${this.details.salesOrderId}.pdf`;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      }
    );
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }


  ngOnInit() {
    this.confirmed = false;
    const id = this.route.snapshot.paramMap.get("id");
    this.loadingPage = true;
    this.storeService.getDetails(id).pipe(first())
      .subscribe(data => {

        if (!data) {
          this.alertService.error("Quote/Sales Order does not exist or you do not have access.");
          this.router.navigate([""]);
          this.loading = false;
        }

        this.details = data.quoteDetailsData;
        this.lineItems = data.quoteDetailsData.lines;

        let compDate = this.addDays(new Date(this.details.documentDate), 28);
        compDate.setHours(0, 0, 0, 0);
        let now = new Date();
        now.setHours(0, 0, 0, 0);

        if (now.getTime() > compDate.getTime()) {
          this.old = true;
          this.alertService.error("Quote older than 28 days - can't convert to order.");
        }

        let curtot = 0;

        if (this.lineItems) {
          this.lineItems.forEach((curitem) => {
            curtot = curtot + (curitem.quantity * curitem.unitPrice);
          });

          this.total = curtot;
        } else {
          this.total = 0;
        }


        this.loadingPage = false;
      }, error => {
        this.alertService.error("Quote/Sales Order does not exist or you do not have access.");
        this.router.navigate([""]);
        this.loadingPage = false;
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(OrderDetailDialogComponent, {
      width: '380px',
      data: { poNumber: this.customerPO, confirmed: this.confirmed, file: this.poFile, regex: this.details.poNumberRegex, regexDesc: this.details.poNumberRegexDescription }
    });

    dialogRef.afterClosed().subscribe(result => {
    if (result.event == "Success") {
        this.convert(result.data.poNumber, result.data.file);
      }
    });
  }

}

@Component({
  selector: 'order-detail-dialog',
  templateUrl: 'order-detail-dialog.component.html',
})
export class OrderDetailDialogComponent implements OnInit  {
  fileToUpload: File = null;
  fileExists: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OrderDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  form: FormGroup;

  onNoClick(): void {
    this.dialogRef.close({ event: 'Cancel' });

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      poNumber: ["", Validators.compose(
        [
          Validators.pattern(this.data.regex),
          Validators.required
        ]
      )]
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.fileExists = true;
  }

  onSubmit() {
    this.data.poNumber = this.form.controls.poNumber.value;
    this.data.confirmed = true;
    this.data.file = this.fileToUpload;
    this.dialogRef.close({ event: "Success", data: this.data });
  }

  
}
