import { Component } from "@angular/core";

import { BlankLayoutCardComponent } from "app/components/blank-layout-card";
import { first } from "rxjs/operators";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService, AuthenticationService } from "../../../services";
import { Router } from "@angular/router";


@Component({
  selector: "app-sign-up",
  styleUrls: ["../../../components/blank-layout-card/blank-layout-card.component.scss"],
  templateUrl: "./sign-up.component.html",
})
export class SignUpComponent extends BlankLayoutCardComponent {

  signUpForm: FormGroup;
  submitted = false;
  loading = false;

  public constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      firstname: ["", Validators.required],
      lastname: ["", Validators.required],
      username: ["", [Validators.required, Validators.email]],
      company: ["", Validators.required],
      password: ["", [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?\"!@$%^&*£-]).{8,}")]],
      confirmPassword: ["", Validators.required],
    },
      {
        validators: this.password.bind(this)
      });
  }

  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get("password");
    const { value: confirmPassword } = formGroup.get("confirmPassword");
    if (!(password === confirmPassword)) {
      formGroup.get("confirmPassword").setErrors({ passwordNotMatch: true });
    };
  }

  get f() { return this.signUpForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.signUpForm.invalid) {
      return;
    }
    this.loading = true;

    this.authenticationService.signup(this.f.firstname.value, this.f.lastname.value, this.f.username.value, this.f.company.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.success === true) {
            this.alertService.error("Success! An email has been sent to your email address for account activation.");
            this.router.navigate(["login"]);
          } else {
            this.alertService.error(data.error);
          }
          this.loading = false;

        },
        error => {
          this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
          this.loading = false;

        });
  }
}
