import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "base-tooltip",
  styleUrls: ["./tooltip.component.scss"],
  template: ``,
})
export class TooltipComponent {
  @HostBinding("innerHTML") @Input()
  content;
  @HostBinding("attr.for") @Input()
  targetId;
  @Input()
  position = "bottom";
  @HostBinding("class.mdl-tooltip--large") @Input()
  large = false;

  @HostBinding("class") private get className() {
    return `mdl-tooltip mdl-tooltip--${this.position}`;
  }
}
