import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { LayoutsModule } from "./layouts";
import { CommonLayoutComponent } from "./layouts/common-layout";
import { LoginComponent } from "./pages/login";

import { AssetsComponent } from "./pages/assets";
import { TicketsComponent } from "./pages/tickets";
import { StoreComponent } from "./pages/store";

import { ContractsComponent } from "./pages/contracts";
import { InvoicesComponent } from "./pages/invoices";
import { AuthGuard, AssetGuard, RepairGuard, StoreGuard } from "./helpers";
import { BlankLayoutComponent } from "app/layouts/blank-layout";

import { SplashComponent } from "./pages/splash";

// TODO: fix this
import { ErrorComponent } from "./pages/error";
import { BasketComponent } from "./pages/store/basket";





@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: "", redirectTo: "app/store", pathMatch: "full", canActivate: [AuthGuard] },
        {
          path: "app", component: CommonLayoutComponent, canActivate: [AuthGuard], children: [
            ////{ path: 'dashboard', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            ////{ path: "assets", component: AssetsComponent, pathMatch: "full", canActivate: [AuthGuard, AssetGuard] },
            //{ path: "assets", component: AssetsComponent, pathMatch: "full", canActivate: [AuthGuard, RepairGuard] },
            //{ path: "assets/:id", component: AssetsComponent, pathMatch: "full", canActivate: [AuthGuard, RepairGuard] },
            //{ path: "repairs", component: AssetsComponent, pathMatch: "full", canActivate: [AuthGuard, RepairGuard] },
            { path: "tickets", component: TicketsComponent, pathMatch: "full", canActivate: [AuthGuard]},
            //{ path: "contracts", component: ContractsComponent, pathMatch: "full", canActivate: [AuthGuard, RepairGuard]},
            //{ path: "invoices", component: InvoicesComponent, pathMatch: "full", canActivate: [AuthGuard, RepairGuard]}
            { path: "store", component: StoreComponent, pathMatch: "full", canActivate: [AuthGuard, StoreGuard] },
            { path: "store/basket", component: BasketComponent, pathMatch: "full", canActivate: [AuthGuard, StoreGuard] }
          ],

        },
        {
          path: "login", component: BlankLayoutComponent, children: [
            {path: "", component: LoginComponent}], 
        },
        {
          path: "splash", component: BlankLayoutComponent, children: [
            { path: "", component: SplashComponent }],
        },
        {
          path: "error", component: BlankLayoutComponent, children: [
            { path: "", component: ErrorComponent }],
        },
      ],
      { useHash: false },
    ),
    LayoutsModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
