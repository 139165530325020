import { Component } from "@angular/core";

import { BlankLayoutCardComponent } from "app/components/blank-layout-card";
import { first } from "rxjs/operators";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService, AuthenticationService } from "../../../services";
import { Router } from "@angular/router";


@Component({
  selector: "app-forgot-password",
  styleUrls: ["../../../components/blank-layout-card/blank-layout-card.component.scss"],
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent extends BlankLayoutCardComponent {
  forgotPasswordForm: FormGroup;
  submitted = false;
  loading = false;

  public constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router
    ) {
    super();
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ["", Validators.required]
    });
  }

  get f() { return this.forgotPasswordForm.controls; }


  onSubmit() {
    this.submitted = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.loading = true;


    this.authenticationService.forgotPassword(this.f.username.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.success === true) {
            this.alertService.error("Password reset link sent.");
            this.router.navigate(["login"]);
          } else {
            this.alertService.error(data.error);
          }
          this.loading = false;

        },
        error => {
          this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
          this.loading = false;

        });
  }
}
