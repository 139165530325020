import { Component } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";

@Component({
  selector: "tickets-selector",
  templateUrl: "tickets.component.html",
})
export class TicketsComponent extends UpgradableComponent {
 
}
