// Modules
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSortModule, MatSelectModule, MatFormFieldModule } from '@angular/material';
import { DashboardModule } from "./pages/dashboard";
import { AssetsModule } from "./pages/assets";
import { LoginModule} from "./pages/login"
import { TicketsModule } from "./pages/tickets"
import { StoreModule } from "./pages/store"
import { SplashModule } from "./pages/splash";
import { MaterialModule } from "./material-module"
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ErrorModule } from "./pages/error";

import { ContractsModule } from "./pages/contracts"
import { InvoicesModule } from "./pages/invoices"

//Components
import { AlertComponent } from "./components/alert";
import { AppComponent } from "./app.component";

// Providers
import { fakeBackendProvider, HelpersModule } from "./helpers";
import { JwtInterceptor, ErrorInterceptor } from "./helpers";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";


@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
  ],
  imports: [
    HelpersModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    LoginModule,
    AssetsModule,
    TicketsModule, 
    StoreModule,
    ContractsModule,
    InvoicesModule,
    MatSortModule,
    SplashModule,
    MatSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    ErrorModule,
  ],
  entryComponents: [
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    
    // provider used to create fake backend
    fakeBackendProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
