import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ThemeModule } from "theme";

import { AssetsComponent } from "./assets.component";
import { AssetsRoutingModule } from "./assets-routing.module";
import { AssetDetailsComponent } from "./details/asset-details.component"

import { MaterialModule } from "./../../material-module"

import { HelpersModule } from "./../../helpers"

import { MatTableExporterModule } from 'mat-table-exporter';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    MaterialModule,
    HelpersModule,
    AssetsRoutingModule,
    MatTableExporterModule
  ],
  declarations: [
    AssetsComponent,
    AssetDetailsComponent
  ],
})
export class AssetsModule { }
