import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ThemeModule } from "theme";

import { StoreComponent } from "./store.component"
import { BasketComponent } from "./basket/basket.component"
import { QuotesComponent, DeleteQuoteDialogComponent } from "./quotes/quotes.component"
import { OrdersComponent } from "./orders/orders.component"
import { OrderDetailComponent, OrderDetailDialogComponent } from "./details/order-detail.component";
import { StoreRoutingModule } from "./store-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { MaterialModule } from "./../../material-module"

import { HelpersModule } from "./../../helpers"

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    StoreRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HelpersModule,
    MatSelectModule,
    NgxMatSelectSearchModule
  ],
  declarations: [
    StoreComponent,
    QuotesComponent,
    OrdersComponent,
    BasketComponent,
    OrderDetailComponent,
    OrderDetailDialogComponent,
    DeleteQuoteDialogComponent
  ],
  entryComponents: [
    OrderDetailDialogComponent,
    DeleteQuoteDialogComponent
  ]
})
export class StoreModule { }
