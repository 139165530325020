import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ThemeModule } from "theme";

import { LoginComponent } from "./login.component";
import { ForgotPasswordComponent } from "./forgot-password/"
import { ResetPasswordComponent } from "./reset-password/"
import { ConfirmAccountComponent } from "./confirm-account/"

import { SignUpComponent } from "./sign-up";
import { MaterialModule } from "./../../material-module"
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginRoutingModule } from "./login-routing.module";


@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    LoginRoutingModule
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    ResetPasswordComponent,
    ConfirmAccountComponent
  ],
  entryComponents: [
    
  ]
})
export class LoginModule { }
