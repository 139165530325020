import { Component } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { AlertService, TicketsService } from "../../../services";
import { Router } from "@angular/router";

@Component({
  selector: "new-ticket-selector",
  templateUrl: "new-ticket.component.html",
  styleUrls: ["./new-ticket.component.scss"],

})
export class NewTicketComponent extends UpgradableComponent {

  formGroup: FormGroup;

  form: FormGroup[];
  overview: boolean;
  hardware: boolean;
  connectivity: boolean;
  device: boolean;
  collection: boolean;
  deployment: boolean;
  deploymentOther: boolean;


  public constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private ticketService: TicketsService) {
    super();
  }

  get formArray(): AbstractControl | null { return this.formGroup.get("formArray"); }


  getFormGroup(id: string) {
    return (this.formArray.get([id]) as FormGroup).controls;
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.formBuilder.group({
          category: ["", Validators.required],
          title: ["", Validators.required]
        }),
        this.formBuilder.group({
          dateTime: [""],
          description: ["", Validators.required]
        }),
        this.formBuilder.group({
          deviceRebooted: [""],
          differentUsb: [false],
          differentPowerSupply: new FormControl(false),
          differentWallSocket: new FormControl(false),
          powerTroubleshootingDetails: [""]
        }),
        this.formBuilder.group({
          connectivityRebooted: new FormControl(false),
          dataEnabled: new FormControl(false),
          differentSim: new FormControl(false),
          wifiEnabled: new FormControl(false),
          differentWifi: new FormControl(false),
          connectivityTroubleshootingDetails: [""]
        }),
        this.formBuilder.group({
          deviceMake: ["", Validators.required],
          deviceId: ["", Validators.required],
          deviceSerial: ["", Validators.required]
        }),
        this.formBuilder.group({
          address: ["", Validators.required],
          contact: ["", Validators.required],
          phone: ["", Validators.required]
        }),
        this.formBuilder.group({
          deploymentDescription: ["", Validators.required],
          targetFolder: [""],
          targetDevice: [""],
          deploymentDate: ["", Validators.required],
          testDevice: [""],
          profileName: [""],
          packageName: [""]
        }),
        this.formBuilder.group({
          deploymentDetails: ["", Validators.required],
          deploymentLinks: [""],
        }),
        this.formBuilder.group({
         
        })
      ])
    });
  }

  onChange(event): void {

    var value = event.value;

    if (value == "hardware") {
      this.overview = true;
      this.hardware = true;
      this.device = true;
      this.connectivity = false;
      this.collection = false;
      this.deployment = false;
      this.deploymentOther = false;
    }

    if (value == "connectivity") {
      this.overview = true;
      this.connectivity = true;
      this.device = true;
      this.hardware = false;
      this.collection = false;
      this.deployment = false;
      this.deploymentOther = false;

    }

    if (value == "software") {
      this.overview = true;
      this.hardware = false;
      this.connectivity = false;
      this.device = false;
      this.collection = false;
      this.deployment = false;
      this.deploymentOther = false;
    }

    if (value == "rma") {
      this.overview = true;
      this.hardware = false;
      this.connectivity = false;
      this.device = true;
      this.collection = true;
      this.deployment = false;
      this.deploymentOther = false;
    }

    if (value == "deploymentNew") {
      this.overview = false;
      this.hardware = false;
      this.connectivity = false;
      this.device = false;
      this.collection = false;
      this.deployment = true;
      this.deploymentOther = true;
    }

    if (value == "deploymentExisting") {
      this.overview = false;
      this.hardware = false;
      this.connectivity = false;
      this.device = false;
      this.collection = false;
      this.deployment = true;
      this.deploymentOther = false;
    }

    if (value == "deploymentOther") {
      this.overview = false;
      this.hardware = false;
      this.connectivity = false;
      this.device = false;
      this.collection = false;
      this.deployment = true;
      this.deploymentOther = true;
    }

    if (value == "other") {
      this.overview = true;
      this.hardware = false;
      this.connectivity = false;
      this.device = false;
      this.collection = false;
      this.deployment = false;
      this.deploymentOther = false;
    }
  }

  onSubmit() {
    var subject = this.getFormGroup("0").title.value;
    var description = [];

    if (this.overview) {
      description.push("----Overview----");
      description.push("Date of Issue: " + this.getFormGroup("1").dateTime.value);
      description.push("Description: " + this.getFormGroup("1").description.value);
    }

    if (this.hardware) {
      description.push("----Hardware Troubleshooting----");
      description.push("Device Rebooted: " + this.getFormGroup("2").deviceRebooted.value);
      description.push("Different USB Tried: " + this.getFormGroup("2").differentUsb.value);
      description.push("Tried different Powersupply: " + this.getFormGroup("2").differentPowerSupply.value);
      description.push("Tried different Wall Socket: " + this.getFormGroup("2").differentWallSocket.value);
      description.push("Additional Troubleshooting: " + this.getFormGroup("2").powerTroubleshootingDetails.value);
    }

    if (this.connectivity) {
      description.push("----Connectivity Troubleshooting----");
      description.push("Device Rebooted: " + this.getFormGroup("3").connectivityRebooted.value);
      description.push("Data Enabled: " + this.getFormGroup("3").dataEnabled.value);
      description.push("Tried different SIM: " + this.getFormGroup("3").differentSim.value);
      description.push("Wifi Enabled: " + this.getFormGroup("3").wifiEnabled.value);
      description.push("Tried different WiFi: " + this.getFormGroup("3").differentWifi.value);
      description.push("Additional Troubleshooting: " + this.getFormGroup("3").connectivityTroubleshootingDetails.value);

    }

    if (this.device) {
      description.push("----Device Details----");
      description.push("Device Make: " + this.getFormGroup("4").deviceMake.value);
      description.push("Device ID: " + this.getFormGroup("4").deviceId.value);
      description.push("Device Serial: " + this.getFormGroup("4").deviceSerial.value);

    }

    if (this.collection) {
      description.push("----Collection Details----");
      description.push("Address: " + this.getFormGroup("5").address.value);
      description.push("Contact: " + this.getFormGroup("5").contact.value);
      description.push("Phone: " + this.getFormGroup("5").phone.value);
    }

    if (this.collection) {
      description.push("----Deployment Request Details----");
      description.push("Description of required deployment: " + this.getFormGroup("6").deploymentDescription.value);
      description.push("Target Folder(s): " + this.getFormGroup("6").targetFolder.value);
      description.push("Target Device(s): " + this.getFormGroup("6").targetDevice.value);
      description.push("Requested Deployment Date: " + this.getFormGroup("6").deploymentDate.value);
      description.push("Test devices: " + this.getFormGroup("6").testDevice.value);
      description.push("Profile Name: " + this.getFormGroup("6").profileName.value);
      description.push("Package Name: " + this.getFormGroup("6").packageName.value);
    }

    if (this.collection) {
      description.push("----Additional Deployment Request Details----");
      description.push("Additional Description of required deployment: " + this.getFormGroup("7").deploymentDetails.value);
      description.push("Additional Deployment Links: " + this.getFormGroup("7").deploymentLinks.value);
    }


    this.ticketService.createTicket(subject, description, this.getFormGroup("0").category.value).subscribe((data) => {
      if (data.error) {
        this.alertService.error("Something went wrong creating the ticket, please try again.");
      }

      this.alertService.success("Ticket Created!", true);
      this.router.navigate(["app/tickets"]);
    });

  }
}
