export class Basket {
  items: BasketItem[];
}

export class BasketItem {

  constructor(
    public name: string,
    public id: number,
    public partNumber: string,
    public image: string,
    public salePrice: number,
    public quantity: number,
    public postageExempt: boolean) {
  }
  
}
