import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { LayoutsModule } from "app/layouts";

import { CommonLayoutComponent } from "../../layouts/common-layout";
import { BasketComponent } from "./basket/basket.component"
import { QuotesComponent } from "./quotes/quotes.component"
import { OrdersComponent } from "./orders/orders.component"
import { OrderDetailComponent } from "./details/order-detail.component"


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "",
        component: CommonLayoutComponent,
        children: [
          { path: "app/store/basket", component: BasketComponent, pathMatch: "full" },
          { path: "app/quotes", component: QuotesComponent, pathMatch: "full" },
          { path: "app/orders", component: OrdersComponent, pathMatch: "full" },
          { path: "app/orders/details/:id", component: OrderDetailComponent, pathMatch: "full" },

        ],
      },
    ]),
    LayoutsModule,
  ],
  exports: [RouterModule],
})
export class StoreRoutingModule { }
