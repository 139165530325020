import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { LayoutsModule } from "app/layouts";
import { BlankLayoutComponent } from "app/layouts/blank-layout";
import { ForgotPasswordComponent } from "./forgot-password";
import { SignUpComponent } from "./sign-up";
import { ResetPasswordComponent } from "./reset-password";
import { ConfirmAccountComponent } from "./confirm-account";


@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "",
        component: BlankLayoutComponent,
        children: [
          { path: "login/sign-up", component: SignUpComponent, pathMatch: "full" },
          { path: "login/forgot-password", component: ForgotPasswordComponent, pathMatch: "full" },
          { path: "login/reset-password", component: ResetPasswordComponent, pathMatch: "full" },
          { path: "login/confirm-account", component: ConfirmAccountComponent, pathMatch: "full" },
        ],
      },
    ]),
    LayoutsModule,
  ],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
