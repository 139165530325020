import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ThemeModule } from "theme";

import { InvoicesComponent } from "./invoices.component";
//import { InvoicesRoutingModule } from "./invoices-routing.module";

import { MaterialModule } from "./../../material-module"

import { HelpersModule } from "./../../helpers"

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    MaterialModule,
    HelpersModule,
    //InvoicesRoutingModule
  ],
  declarations: [
    InvoicesComponent
  ],
})
export class InvoicesModule { }
