import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ThemeModule } from "theme";

import { ErrorComponent } from "./error.component";

import { MaterialModule } from "./../../material-module"
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    ErrorComponent
  ],
  entryComponents: [

  ]
})
export class ErrorModule { }

