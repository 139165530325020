import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Basket, BasketItem } from "../models/basket";
import { map, } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class StoreService {
  private baseUrl: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.baseUrl = baseUrl;
  }


  clearBasket() {
    var basket = new Basket();
    basket.items = [];
    sessionStorage.setItem("basket", JSON.stringify(basket));
  }




  createQuote(request: QuoteRequest) {
    return this.http.post<CreateQuoteResponse>(`${this.baseUrl}api/SalesOrder/CreateQuote`, request)
      .pipe(map(response => {
        return response;
      }));
  }

  getProducts() {
    return this.http.get<GetStockResponse>(`${this.baseUrl}api/Stock/GetStockList`)
      .pipe(map<GetStockResponse, GetStockResponse>((x) => {
        return x;
      }));
  }

  getDetails(id) {
    return this.http.get<GetQuoteDetailsResponse>(`${this.baseUrl}api/SalesOrder/GetQuoteDetails?salesOrderId=`+id)
      .pipe(map<GetQuoteDetailsResponse, GetQuoteDetailsResponse>((x) => {
        return x;
      }));
  }

  getQuotes() {
    return this.http.get<GetQuotesResponse>(`${this.baseUrl}api/SalesOrder/GetQuotes`)
      .pipe(map<GetQuotesResponse, SalesOrderData[]>(response => {
        return response.quotesData;
      }));
  }

  getOrders() {
    return this.http.get<GetQuotesResponse>(`${this.baseUrl}api/SalesOrder/GetOrders`)
      .pipe(map<GetQuotesResponse, SalesOrderData[]>(response => {
        return response.quotesData;
      }));
  }

  getPostageCosts() {
    return this.http.get<GetPostageCostsResponse>(`${this.baseUrl}api/SalesOrder/GetPostageCosts`)
      .pipe(map<GetPostageCostsResponse, PostageCostData[]>(response => {
        return response.postageCosts;
      }));
  }


  /*Baskets*/
  addToBasket(item: BasketItem) {
    var basket = JSON.parse(sessionStorage.getItem("basket"));

    if (!basket) {
      basket = new Basket();
      basket.items = [];
    }

    var found = false;

    basket.items.forEach((curitem) => {
      if (curitem.id === item.id) {

        if (curitem.quantity !== 99) {
          curitem.quantity = curitem.quantity + item.quantity;
        }

        found = true;
      }
    });

    if (found === false) {
      basket.items.push(item);
    }

    sessionStorage.setItem("basket", JSON.stringify(basket));
  }


  setQuantity(id: number, qty: number) {
    var basket = JSON.parse(sessionStorage.getItem("basket"));
    basket.items.forEach((curitem) => {
      if (curitem.id === id) {
        curitem.quantity = qty;
      }
    });
    sessionStorage.setItem("basket", JSON.stringify(basket));
  }

  removeFromBasket(id: number) {
    var basket = JSON.parse(sessionStorage.getItem("basket"));
    basket.items = basket.items.filter(obj => obj.id !== id);
    sessionStorage.setItem("basket", JSON.stringify(basket));

  }

  getBasket(): Basket {
    var subj = JSON.parse(sessionStorage.getItem("basket"));
    return subj;
  }

  convertToOrder(salesOrderId: number, poNumber: string, fileToUpload: File) {
    const endpoint = `${this.baseUrl}api/SalesOrder/ConvertQuote`;
    const formData: FormData = new FormData();
    if (fileToUpload !== null) {
      formData.append('fileKey', fileToUpload, fileToUpload.name);
    }
    formData.append('salesOrderId', salesOrderId.toString());
    formData.append('poNumber', poNumber);
    return this.http
      .post<ConvertQuoteResponse>(endpoint, formData)
      .pipe(map<ConvertQuoteResponse, ConvertQuoteResponse>(response => { return response; }));
    //return this.http.get<ConvertQuoteResponse>(`${this.baseUrl}api/SalesOrder/ConvertQuote?salesOrderId=${salesOrderId}&customerPoNumber=${poNumber}`)
    //  .pipe(map(response => {
    //    return response;
    //  }));    
  }

  delete(salesOrderId: number) {
    return this.http.get<DeleteQuoteResponse>(`${this.baseUrl}api/SalesOrder/DeleteQuote?salesOrderId=${salesOrderId}`)
      .pipe(map(response => {
        return response;
      }));    
  }

  downloadQuote(salesOrderId: number): Observable<HttpEvent<Blob>>  {
    const endpoint = `${this.baseUrl}api/SalesOrder/PdfQuote?salesOrderId=${salesOrderId}`;

    return this.http.request(new HttpRequest(
      'GET',
      endpoint,
      null,
      {
        reportProgress: false,
        responseType: 'blob'
      }));
  }
  
}


export class GetQuoteDetailsResponse {
  error: string;
  quoteDetailsData: QuoteDetailsData;
}

export class QuoteDetailsData {
  salesOrderId: number;
  lastUpdated: Date;
  type: string;
  status: string;
  reference: string;
  documentDate: Date;
  orderNumber: string;
  deliveryName: string;
  deliveryAddress1: string;
  deliveryAddress2: string;
  deliveryAddress3: string;
  deliveryTown: string;
  deliveryCounty: string;
  deliveryPostCode: string;
  poNumberRegex: string;
  poNumberRegexDescription: string;
  lines: QuoteDetailsLineData[];
}

export class QuoteDetailsLineData {
  salesOrderLineId: number;
  lastUpdated: Date;
  productId: number;
  partNumber: string;
  description: string;
  quantity: number;
  unitPrice: number;
  imageData: string;
}

export class GetStockResponse {
  stockData: CategoryData[];
  error: string;
}

export class CategoryData {
  name: string;
  hasImage: boolean;
  imageData: string;
  products: ProductData[];
}

export class ProductData {
  id: number;
  lastUpdated: Date;
  productId: number;
  partNumber: string;
  description: string;
  parentProductId: string;
  currencyCode: string;
  price: number;
  cost: number;
  hasImage: boolean;
  imageData: string;
}


export class OrderDetails {
  id: number;
  status: string;
  type: string;
  lastUpdated: Date;
  orderNumber: string;
  reference: string;
  deliveryName: string;
  deliveryAddress1: string;
  deliveryAddress2: string;
  deliveryAddress3: string;
  deliveryTown: string;
  deliveryCounty: string;
  deliveryPostCode: string;
  lines: LineItem[];
}

export class LineItem {
  salesOrderLineId: number;
  quantity: number;
  productId: number;
  partNumber: string;
  description: string;
  unitPrice: number;
  image: string;

}

export class SalesOrderData {
  salesOrderId: number;
  reference: string;
  lastUpdated: Date;
  documentDate: Date;
  totalPrice: number;
  status: string;
  orderNumber: string;
}

export class GetQuoteDetails {
  error: string;
  data: OrderDetails;
}

export class GetQuotesResponse {
  error: string;
  quotesData: SalesOrderData[];
}

export class GetOrdersResponse {
  error: string;
  data: SalesOrderData[];
}

export class QuoteRequest {
  constructor(public customerPoNumber: string,
    public deliveryCustomerNumber: number,
    public deliveryAddress: QuoteAddressData,
    public lines: QuoteOrderLine[]
  ) {

  }
}

export class QuoteAddressData {
  constructor(
    public name: string,
    public address1: string,
    public address2: string,
    public address3: string,
    public town: string,
    public county: string,
    public postCode: string) {

  }
}

export class QuoteOrderLine {
  constructor(
    public productId: number,
    public quantity: number
  ) { }
}

export class CreateQuoteResponse {
  error: string;
  salesOrderId: number;
}

export class ConvertQuoteResponse {
  error: string;
  
}

export class DeleteQuoteResponse {
  error: string;

}

export class GetPostageCostsResponse {
  error: string;
  postageCosts: PostageCostData[];
}

export class PostageCostData {
  quoteTotal: number;
  postage: number;
}

