import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models";

export class LoginResponse {
  user: User;
  error: string;
}

export class AsgardeoLoginResponse {
  access_token: string;
  scope: string[];
  id_token: string;
  token_type: string;
  expires_in: number;
  error: string;
}

export class ForgotPasswordResponse {
  success: boolean;
  error: string;
}

export class ResetPasswordResponse {
  success: boolean;
  error: string;
}

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  currentUser: Observable<User>;
  private readonly currentUserSubject: BehaviorSubject<User>;
  private readonly baseUrl: string;
  private readonly http: HttpClient;
  private userIdToken: string;

  constructor(http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
    this.currentUser = this.currentUserSubject.asObservable();
    this.baseUrl = baseUrl;
    this.http = http;
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  forgotPassword(username: string) {
    return this.http.post<ForgotPasswordResponse>(`${this.baseUrl}api/Authentication/ForgotPassword`, { 'username': username })
      .pipe(map(response => {
        return new AuthenticationServiceResponse(response.success, response.error);
      }));
  }

  resetPassword(key: string, password: string) {
    return this.http.post<ResetPasswordResponse>(`${this.baseUrl}api/Authentication/ResetPassword`, { 'key': key, 'password': password })
      .pipe(map(response => {
        return new AuthenticationServiceResponse(response.success, response.error);
      }));
  }

  confirmAccount(key: string) {
    return this.http.post<ResetPasswordResponse>(`${this.baseUrl}api/Authentication/ConfirmAccount`, { 'key': key })
      .pipe(map(response => {
        return new AuthenticationServiceResponse(response.success, response.error);
      }));
  }

  signup(firstname: string, lastname: string, username: string, company: string, password: string) {
    return this.http.post<LoginResponse>(`${this.baseUrl}api/Authentication/SignUp`, { 'firstname': firstname, 'lastname': lastname, 'company': company, 'username': username, 'password': password })
      .pipe(map(response => {

        if (response.error) {
          return new AuthenticationServiceResponse(false, response.error);
        }

        return new AuthenticationServiceResponse(true, "");
      }));
  }

  login(username: string, password: string) {
    return this.http.post<LoginResponse>(`${this.baseUrl}api/Authentication/Login`, { 'username': username, 'password': password })
      .pipe(map(response => {

        if (response.error) {
          return new AuthenticationServiceResponse(false, response.error);
        }

        if (response.user && response.user.token) {
          sessionStorage.setItem("currentUser", JSON.stringify(response.user));
          this.currentUserSubject.next(response.user);
        }

        return new AuthenticationServiceResponse(true, "");
      }));
  }

  logout() {
    sessionStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    this.logoutAsgardeo();
  }

  logoutAsgardeo() {
    var url:string = "https://api.eu.asgardeo.io/t/renovotec/oauth2/logout";
    const clientId = '9P1SJRsEueTLrtpFcN4P3TXr68wa';
    const asgardeoLogoutUrl = `https://api.eu.asgardeo.io/t/renovotec/oidc/logout?client_id=${clientId}&post_logout_redirect_uri=` + this.baseUrl + "login";
    document.location.href = asgardeoLogoutUrl;
  }

  loginasgardeo() {
    // TODO: Set this up properly so it's not hardcoded.
    //document.location.href = "https://api.eu.asgardeo.io/t/renovotec/oauth2/authorize?response_type=code&client_id=9P1SJRsEueTLrtpFcN4P3TXr68wa&scope=openid&redirect_uri=http%3A%2F%2Flocalhost%3A58332";
    /*document.location.href = `https://api.eu.asgardeo.io/t/renovotec/oauth2/authorize?response_type=code&client_id=9P1SJRsEueTLrtpFcN4P3TXr68wa&scope=openid%20profile&redirect_uri=https://localhost:58332/login`;*/
    const asgardeoLoginUrl = "https://api.eu.asgardeo.io/t/renovotec/oauth2/authorize?response_type=code&client_id=9P1SJRsEueTLrtpFcN4P3TXr68wa&scope=openid%20profile&redirect_uri=" + this.baseUrl + "splash";
    document.location.href = asgardeoLoginUrl;
  }

  getAsgardeoAuthToken(authCode: string) {
    return this.http.post<LoginResponse>(`${this.baseUrl}api/Authentication/GetAsgardeoAuthToken`, { 'authcode': authCode })
      .pipe(map(response => {

      if (response.error) {
        return new AuthenticationServiceResponse(false, response.error);
      }

        if (response.user && response.user.token) {
          this.userIdToken = response.user.token;
          sessionStorage.setItem("currentUser", JSON.stringify(response.user));
          this.currentUserSubject.next(response.user);
      }

      return new AuthenticationServiceResponse(true, "");
    }));
  }
}


export class AuthenticationServiceResponse {
  constructor(public success: boolean, public error: string) { }
}
