import { Component, ViewChild, HostBinding, OnInit } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { StoreService, SalesOrderData } from "../../../services";
import { Router } from "@angular/router";



@Component({
  selector: "orders-selector",
  templateUrl: "orders.component.html",
  styleUrls: ["./orders.component.scss"],

})
export class OrdersComponent extends UpgradableComponent implements OnInit {

  @HostBinding("class.mdl-grid") private readonly mdlGrid = true;
  @HostBinding("class.mdl-cell") private readonly mdlCell = true;
  @HostBinding("class.mdl-cell--12-col-desktop") private readonly mdlCell12ColDesktop = true;
  @HostBinding("class.mdl-cell--12-col-tablet") private readonly mdlCell12ColTablet = true;
  @HostBinding("class.mdl-cell--4-col-phone") private readonly mdlCell4ColPhone = true;
  @HostBinding("class.mdl-cell--top") private readonly mdlCellTop = true;
  @HostBinding("class.ui-tables") private readonly uiTables = true;

  displayedColumns: string[] = ["orderNumber", "reference", "status", "total", "lastUpdated"];
  ordersResponseDataSource = new MatTableDataSource<SalesOrderData>();
  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    this.ordersResponseDataSource.paginator = matPaginator;
  }
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.ordersResponseDataSource.sort = matSort;
  }

  loading = false;
  noOrders = false;

  public constructor(private readonly tablesService: StoreService,
    private readonly router: Router) {
    super();
    this.loading = true;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ordersResponseDataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {

    this.tablesService.getOrders().subscribe(result => {
      if (result.length === 0) {
        this.noOrders = true;
      }
      this.ordersResponseDataSource = new MatTableDataSource<SalesOrderData>(result);
      this.loading = false;
    });

    componentHandler.upgradeAllRegistered();
  }

  ngOnInit(): void {

  }

  getDetails(row: SalesOrderData) {
    //this.router.navigate(['app/tickets/details/' + row.ticketNumber]);

    const url = this.router.serializeUrl(
      this.router.createUrlTree([`app/quotes/details/` + row.salesOrderId])
    );

    window.open(url, "_blank");

  }

  

}
