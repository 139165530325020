import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { BlankLayoutCardComponent } from "app/components/blank-layout-card";
import { MessageMenuComponent } from "app/components/message-menu";
import { NotificationMenuComponent } from "app/components/notification-menu";
import { SidebarComponent } from "app/components/sidebar";
import { ThemeModule } from "theme";
import { BlankLayoutComponent } from "./blank-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonLayoutComponent } from "./common-layout";

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    CommonLayoutComponent,
    BlankLayoutComponent,
    BlankLayoutCardComponent,
    SidebarComponent,
    MessageMenuComponent,
    NotificationMenuComponent,
  ],
  exports: [
    CommonLayoutComponent,
    BlankLayoutComponent,
  ],
})
export class LayoutsModule { }
