import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ThemeModule } from "theme";

import { ContractsComponent } from "./contracts.component";
//import { ContractsRoutingModule } from "./contracts-routing.module";

import { MaterialModule } from "./../../material-module"

import { HelpersModule } from "./../../helpers"

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    MaterialModule,
    HelpersModule,
    //ContractsRoutingModule
  ],
  declarations: [
    ContractsComponent
  ],
})
export class ContractsModule { }
