import { BlankLayoutCardComponent } from "app/components/blank-layout-card";


import { Component, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { AlertService, AuthenticationService } from "../../services";



@Component({
  selector: "app-login",
  styleUrls: ["./login.component.scss"],
  templateUrl: "./login.component.html",
})
export class LoginComponent {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  code: string;
  private readonly baseUrl: string;

  public constructor(
    @Inject("BASE_URL") baseUrl: string,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public dialog: MatDialog) {

    //super();
    //if (this.authenticationService.currentUserValue) {
    //  this.router.navigate(["/"]);
    //}

    this.baseUrl = baseUrl;
  }

  ngOnInit() {

    // TODO: Redirect to asgardeo login
    const asgardeoLoginUrl = "https://api.eu.asgardeo.io/t/renovotec/oauth2/authorize?response_type=code&client_id=9P1SJRsEueTLrtpFcN4P3TXr68wa&scope=openid%20profile&redirect_uri=" + this.baseUrl + "splash";
    document.location.href = asgardeoLoginUrl;


    //this.loginForm = this.formBuilder.group({
    //  username: ["", Validators.required],
    //  password: ["", Validators.required]
    //});

    //// get return url from route parameters or default to '/'
    //this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    //// Get the authorization code from the page parameters
    //this.code = this.route.snapshot.queryParams["code"] || null;
    //if (this.code != null) {
    //  this.sendAuthToken();
    //}


  }

  //get f() { return this.loginForm.controls; }


  //onSubmit() {
  //  this.submitted = true;

  //  this.alertService.clear();

  //  // stop here if form is invalid
  //  if (this.loginForm.invalid) {
  //    return;
  //  }


  //  this.loading = true;
  //  this.authenticationService.login(this.f.username.value, this.f.password.value)
  //    .pipe(first())
  //    .subscribe(
  //      data => {
  //        if (data.success === true) {
  //          this.router.navigate([this.returnUrl]);
  //        } else {
  //          this.alertService.error(data.error);
  //        }
  //        this.loading = false;
  //      },
  //      error => {
  //        this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
  //        this.loading = false;
  //      });
  //}

  loginasgardeo() {
    this.authenticationService.loginasgardeo();
  }
}

