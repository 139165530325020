import { Component, OnInit, HostBinding  } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { TicketsService, TicketDetails, TicketHistory, TicketAttachment } from "../../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService, AlertService } from "../../../services";
import { User } from "../../../models"
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { MatDialog } from '@angular/material';
import { AddAttachmentComponent, AddNoteComponent } from '../../tickets';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpEventType } from "@angular/common/http";

@Component({
  selector: "ticket-details-selector",
  templateUrl: "ticket-details.component.html",
  styleUrls: ["./ticket-details.component.scss"],

})
export class TicketDetailsComponent extends UpgradableComponent implements OnInit {

  @HostBinding('class.mdl-grid') public readonly mdlGrid = true;
  @HostBinding('class.ui-typography') public readonly uiTypography = true;
  @HostBinding('class.mdl-grid--no-spacing') public readonly mdlGridNoSpacing = true;

  details: TicketDetails;
  loading: boolean;
  isRefreshing: boolean;
  history: TicketHistory[];
  attachments: TicketAttachment[];
  form: FormGroup;
  form2: FormGroup;
  private currentUserSubject: BehaviorSubject<User>;
  user: string;
  fileToUpload: File = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: TicketsService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public dialog: MatDialog) {
      super();
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
      this.user = this.currentUserSubject.value.username;
      this.details = new TicketDetails();
      this.loading = true;
  }

  openUploadDialog() {
    let dialogRef = this.dialog.open(AddAttachmentComponent, {
      width: '60%',

      position: {
        top: '50vh',

        left: '50vw'
      },
      panelClass: 'makeItMiddle',
      data: {
        ticketId: this.details.ticketNumber
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refresh();
    });
  }

  openNoteDialog() {
    let dialogRef = this.dialog.open(AddNoteComponent, {
      width: '80%',
      height: '80%',
      position: {
        top: '50vh',
        left: '50vw'
      },
      panelClass: 'makeItMiddle',
      data: {
        ticketId : this.details.ticketNumber
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refresh();
    });
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      description: ["", Validators.required]
    });
    this.form2 = this.formBuilder.group({
      attachmentDescription: ["", Validators.required]
    });
    this.refresh();
  }

  refresh() {
    this.isRefreshing = true;
    const id = this.route.snapshot.paramMap.get("id");
    this.service.getTicketDetails(id).pipe(first())
      .subscribe(data => {

        if (data.error) {
          this.alertService.error("Ticket does not exist or you do not have access.");
          this.router.navigate([""]);
          this.loading = false;
          this.isRefreshing = false;

        }
        this.details = data.ticketDetails;
        this.history = data.ticketDetails.ticketActions;
        this.attachments = data.ticketDetails.ticketAttachments;
        this.loading = false;
        this.isRefreshing = false;

      },
        error => {
          this.alertService.error("Ticket does not exist or you do not have access.");
          this.loading = false;
          this.isRefreshing = false;
        });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFileToActivity() {
    this.service.postFile(this.fileToUpload, this.details.ticketNumber, this.form2.controls.attachmentDescription.value).subscribe(data => {

    }, error => {
      console.log(error);
    });
  }

  downloadAttachment(attachmentId: string, filename: string) {
    this.service.downloadAttachment(attachmentId).subscribe(
      data => {
        switch (data.type) {
          //case HttpEventType.DownloadProgress:
          //  this.downloadStatus.emit({ status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100) });
          //  break;
          case HttpEventType.Response:
            //this.downloadStatus.emit({ status: ProgressStatusEnum.COMPLETE });
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = filename;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      }
      //,
      //error => {
        //this.downloadStatus.emit({ status: ProgressStatusEnum.ERROR });
      //}
    );
  }

}
