import { Component } from '@angular/core';
import { BlankLayoutCardComponent } from "app/components/blank-layout-card";
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from "../../services";

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})

export class ErrorComponent extends BlankLayoutCardComponent {
  private errorMessage: string;

  constructor(private route: ActivatedRoute, private readonly authService: AuthenticationService) {
    super();
    //this.errorMessage = this.route.snapshot.paramMap.get('message');
  }

  navigateToLogout() {
    this.authService.logout();
  }
}
