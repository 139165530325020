import { Component } from "@angular/core";

import { BlankLayoutCardComponent } from "app/components/blank-layout-card";
import { first } from "rxjs/operators";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService, AuthenticationService } from "../../../services";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";


@Component({
  selector: "app-reset-password",
  styleUrls: ["../../../components/blank-layout-card/blank-layout-card.component.scss"],
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent extends BlankLayoutCardComponent {
  resetPasswordForm: FormGroup;
  submitted = false;
  confirmKey: string;
  loading = false;



  public constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.confirmKey = this.route.snapshot.queryParamMap.get("confirmationKey");

    if (this.confirmKey == null) {
      this.router.navigate([""]);
    }
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?\"!@$%^&*£-]).{8,}")]],
      confirmPassword: ["", Validators.required],
    },
      {
        validators: this.password.bind(this)
      });
  }

  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get("password");
    const { value: confirmPassword } = formGroup.get("confirmPassword");
    if (!(password === confirmPassword)) {
      formGroup.get("confirmPassword").setErrors({ passwordNotMatch: true });
    };
  }

  get f() { return this.resetPasswordForm.controls; }


  onSubmit() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;


    this.authenticationService.resetPassword(this.confirmKey, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.success === true) {
            this.alertService.error("Password has been reset");
            this.router.navigate(["login"]);
          } else {
            this.alertService.error(data.error);
          }
          this.loading = false;

        },
        error => {
          this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
          this.loading = true;

        });
  }
}
