import { Component, OnInit, HostBinding, OnDestroy, ViewChild, AfterViewInit } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { StoreService, QuoteRequest, QuoteAddressData, QuoteOrderLine, AlertService, LocationsService, LocationData, LocationsData, GetLocationsResponse, PostageCostData } from '../../../services';
import { Basket, BasketItem } from "../../../models/basket";
import { Router } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { first, takeUntil, take } from "rxjs/operators";
import { MatSelectModule } from '@angular/material';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from "rxjs";



@Component({
  selector: "basket-selector",
  templateUrl: "basket.component.html",
  styleUrls: ["./basket.component.scss"],

})
export class BasketComponent extends UpgradableComponent implements OnInit, AfterViewInit, OnDestroy {
  quoteForm: FormGroup;
  basketItems: BasketItem[];
  total: number;
  subTotal: number;
  submitted: boolean;
  loading: boolean;
  isAdmin: boolean;
  selectedLocation: any;
  assignedLocation: LocationData;
  locations: LocationData[];
  name: string;
  add1: string;
  add2: string;
  add3: string;
  town: string;
  county: string;
  postCode: string;
  postageCosts: PostageCostData[];
  postage: number;
  reactiveFormsModule: ReactiveFormsModule;
  public locationCtrl: FormControl = new FormControl(null);
  public locationFilterCtrl: FormControl = new FormControl('');
  public filteredLocations: ReplaySubject<LocationData[]> = new ReplaySubject<LocationData[]>(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();
  

  constructor(
    private formBuilder: FormBuilder,
    public storeService: StoreService,
    private alertService: AlertService,
    private locationsService: LocationsService,

    private readonly router: Router) {
    super();
  }
  get f() { return this.quoteForm.controls; }

  ngOnInit() {
    this.submitted = false;
    this.updateBasket();
    this.quoteForm = this.formBuilder.group({
      deliveryname: ["", Validators.required],
      address1: ["", Validators.required],
      address2: [""],
      town: [""],
      county: [""],
      postcode: ["", Validators.required],
      
    });

    this.locationsService.getLocations().pipe(first()).subscribe((data) => {
      if (data.error) {
        this.alertService.error("Something went wrong, please try again.  If the problem persists, contact helpdesk.");
        this.router.navigate([""]);
      }

      this.assignedLocation = data.locationsData.assignedLocation;
      this.locations = data.locationsData.locations;

      this.filteredLocations.next(this.locations.slice());

      if (this.assignedLocation) {
        //default address
        this.name = this.assignedLocation.name;
        this.add1 = this.assignedLocation.address1;
        this.add2 = this.assignedLocation.address2;
        this.add3 = this.assignedLocation.address3;
        this.town = this.assignedLocation.town;
        this.county = this.assignedLocation.county;
        this.postCode = this.assignedLocation.postCode;
      } else {
        this.selectedLocation = this.locations[0].id;
        //default address
        this.name = this.locations[0].name;
        this.add1 = this.locations[0].address1;
        this.add2 = this.locations[0].address2;
        this.add3 = this.locations[0].address3;
        this.town = this.locations[0].town;
        this.county = this.locations[0].county;
        this.postCode = this.locations[0].postCode;
      }

      //check for any addresses
      let addressFound: boolean = false;
      if (data.locationsData.assignedLocation.address1 != null && data.locationsData.assignedLocation.address1 != "") {
        addressFound = true
      }
      data.locationsData.locations.forEach(x => { if (x.address1 != null && x.address1 != "") { addressFound = true } })
      if (!addressFound) {
        //no addresses found
        this.alertService.error("No addresses for this location - please contact support.", false, true);
      }

    }, error => {
      this.alertService.error("Something went wrong, please try again.  If the problem persists, contact helpdesk.");
      this.router.navigate([""]);
    });

    this.isAdmin = this.locationsService.isAdmin();

    this.storeService.getPostageCosts().pipe(first()).subscribe((data) => {
      this.postageCosts = data;
      this.updateBasket();
    }, error => {
      this.alertService.error("Something went wrong, please try again.  If the problem persists, contact helpdesk.");
      this.router.navigate([""]);
    });

    this.locationCtrl.setValue(this.locations);
    this.locationFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(changes => {
        this.filterLocations();
      });


  }

  ngAfterViewInit() {
    this.setInitialValue();
    this.selectedLocation = this.assignedLocation;
  }

  ngOnDestroy() {
    this._onDestroy.next();

    this._onDestroy.complete()
  }

  protected setInitialValue() {
    this.filteredLocations
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredLocations are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: LocationData, b: LocationData) => a.id === b.id;
      });
  }

  protected filterLocations() {
    if (!this.locations) {
      return;
    }
    // get the search keyword
    let search = this.locationFilterCtrl.value;
    if (!search) {
      this.filteredLocations.next(this.locations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the locations
    this.filteredLocations.next(
      this.locations.filter(location => location.description.toLowerCase().indexOf(search) > -1)
    );
  }

  onSubmit() {
    this.submitted = true;

    if (this.quoteForm.invalid) {
      return;
    }

    this.loading = true;

    var items = [];

    this.basketItems.forEach((curitem) => {
      items.push(new QuoteOrderLine(curitem.id, curitem.quantity));
    });

    this.storeService.createQuote(new QuoteRequest(
      null,
      this.selectedLocation,
      new QuoteAddressData(
        this.f.deliveryname.value,
        this.f.address1.value,
        this.f.address2.value,
        "",
        this.f.town.value,
        this.f.county.value,
        this.f.postcode.value), items)).pipe(first()).subscribe((data) => {
          if (data.error) {
            this.alertService.error(data.error);
          } else {
            this.alertService.error("Success! Your quote has been created.");
            if (data.salesOrderId) {
              this.router.navigate([`app/orders/details/` + data.salesOrderId]);
            }
            else {
              this.router.navigate(["app/quotes"]);
            }
            this.storeService.clearBasket();
          }

          this.loading = false;
        }, error => {
          this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
          this.loading = false;
    });

  }

  createQuote() {

  }

  removeItem(id) {
    this.storeService.removeFromBasket(id);
    this.updateBasket();
  }

  onChangeValue(valueString: string, id: number) {
    var value: number = parseInt(valueString);
    if (!value || value < 1 || value > 99) {
      value = 1;
    }
    this.storeService.setQuantity(id, value);
    this.updateBasket();
  }


  navigateToStore() {
    this.router.navigate(["app/store"]);
  }

  updateBasket() {
    var basket = this.storeService.getBasket();
    if (basket && basket.items) {
      this.basketItems = basket.items;
    } else {
      this.basketItems = [];
    }

    var curtot = 0;

    if (basket && basket.items) {
      basket.items.forEach((curitem) => {
        curtot = curtot + (curitem.quantity * curitem.salePrice);
      });
      this.subTotal = curtot;
      this.calculatePostage(this.subTotal, basket.items);
    } else {
      this.total = 0;
      this.subTotal = 0;
    }
  }

  calculatePostage(cost: number, items) {
    if (!this.postageCosts) { return; }

    let freePostage = true;
    for (let item of items) {
      if (item.postageExempt == false) {
        freePostage = false;
      }
    }

    if (freePostage == true) {
      this.postage = 0;
      this.total = cost;
      return;
    }

    const orderedPostageCosts = this.postageCosts.sort((n1, n2) => n2.quoteTotal - n1.quoteTotal);
    for (let postageCost of orderedPostageCosts) {
      if (cost >= postageCost.quoteTotal) {
        this.postage = postageCost.postage;
        this.total = cost + postageCost.postage;
        break;
      }
    }
  }

  locationUpdate(event) {
    for (let i = 0; i < this.locations.length; i++) {
      if (this.locations[i].id === event.value.id) {
        this.name = this.locations[i].name;
        this.add1 = this.locations[i].address1;
        this.add2 = this.locations[i].address2;
        this.add3 = this.locations[i].address3;
        this.town = this.locations[i].town;
        this.county = this.locations[i].county;
        this.postCode = this.locations[i].postCode;

        this.selectedLocation = this.locations[i];
        break;
      }
    }
  }
}


