import { Component, OnInit } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { AssetsService, AssetDetails } from "../../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService, AlertService } from "../../../services";
import { User } from "../../../models"
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { MatDialog } from '@angular/material';
//import { AddAttachmentComponent, AddNoteComponent } from '../../tickets';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "asset-details-selector",
  templateUrl: "asset-details.component.html",
  styleUrls: ["./asset-details.component.scss"],

})
export class AssetDetailsComponent extends UpgradableComponent implements OnInit {

  details: AssetDetails;
  loading: boolean;
  //history: TicketHistory[];
  addingNote: boolean;
  form: FormGroup;
  private currentUserSubject: BehaviorSubject<User>;
  user: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: AssetsService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public dialog: MatDialog) {
    super();
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
    this.user = this.currentUserSubject.value.username;
    this.details = new AssetDetails();
    this.loading = true;
    this.addingNote = false;

  }

  //openUploadDialog() {
  //  let dialogRef = this.dialog.open(AddAttachmentComponent, { width: '75%', height: '75%' });
  //}

  //openNoteDialog() {
  //  let dialogRef = this.dialog.open(AddNoteComponent, { width: '75%' });
  //}

  ngOnInit() {

    this.form = this.formBuilder.group({
      description: ["", Validators.required]
    });
    this.refresh();
  }

  //onSubmit() {
  //  this.addingNote = true;
  //  this.service.addNote(this.details.ticketNumber, this.form.controls.description.value).subscribe(data => {

  //    if (data.error) {
  //      this.alertService.error("Unable to add note, please try again.");
  //    } else {
  //      this.alertService.error("Note Added!");
  //      this.form.reset();
  //      this.refresh();
  //    }
  //    this.addingNote = false;
  //  });
  //}

  refresh() {
    const id = this.route.snapshot.paramMap.get("id");
    this.service.getAssetDetails(id).pipe(first())
      .subscribe(data => {

        if (data.error) {
          this.alertService.error("Asset does not exist or you do not have access.");
          this.router.navigate([""]);
          this.loading = false;
        }
        this.details = data.assetDetails;
        //this.history = data.ticketDetails.ticketActions;
        this.loading = false;
      },
        error => {
          this.alertService.error("Asset does not exist or you do not have access.");
          this.router.navigate([""]);
          this.loading = false;
        });
  }

}
