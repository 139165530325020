import { Component, OnInit, ViewChild } from '@angular/core';

import { TicketsService } from '../../../services';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {  AlertService } from "../../../services";
import { Injectable, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
@Component({
  selector: 'app-dialog',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss']
})

export class AddNoteComponent implements OnInit {
  addingNote: boolean;

  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddNoteComponent>,
    public ticketService: TicketsService,
    private formBuilder: FormBuilder,
    private service: TicketsService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      description: ["", Validators.required]
    });
    this.addingNote = false;
  }

  onSubmit() {
    this.addingNote = true;
    this.service.addNote(this.data.ticketId, this.form.controls.description.value).subscribe(data => {
    
      if (data.error) {
        this.alertService.error("Unable to add note, please try again.");
      } else {
        this.alertService.error("Note Added!");
        this.form.reset();
        this.dialogRef.close();
      }
      this.addingNote = false;
    });
  }
}
