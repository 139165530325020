////import { Injectable } from "@angular/core";

////@Injectable({ providedIn: "root" })
////export class AssetsService {

////  private advanceTableData = [
////    ["Asset Number",
////      "01661700607",
////      "851-095-131",
////      "Univ Supply, 12V 48W, RA 2.5x5.5 Level V",
////      "/Date(1553880607177)/"
////    ],
////    ["Asset Number",
////      "02221822132",
////      "PX6C010000000030",
////      "PX6C, DT/TTR, UNIV FW,16M/32M, 300dpi",
////      "/Date(1553880607223)/"
////    ],
////    ["Asset Number",
////      "02761600330",
////      "871-033-021",
////      "CN50/CN51,Desktop Single Dock, RoHS",
////      "/Date(1553880607240)/"
////    ],
////    ["Asset Number",
////      "02821443085",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725643580)/"
////    ],
////    ["Asset Number",
////      "02821443086",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725643720)/"
////    ],
////    ["Asset Number",
////      "02821443087",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725643827)/"
////    ],
////    ["Asset Number",
////      "02821443088",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725643933)/"
////    ],
////    ["Asset Number",
////      "02821443089",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725644047)/"
////    ],
////    ["Asset Number",
////      "02821443090",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725644157)/"
////    ],
////    ["Asset Number",
////      "02821443091",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725644267)/"
////    ],
////    ["Asset Number",
////      "02821443092",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725644377)/"
////    ],
////    ["Asset Number",
////      "02821443096",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725644487)/"
////    ],
////    ["Asset Number",
////      "02821443097",
////      "CK71AA4DC00W1400",
////      "CK71 EV12,Cam,WLAN Only,WEH-P,WWE,SS/ICP",
////      "/Date(1554725644620)/"
////    ],
////    ["Asset Number",
////      "044X1700135",
////      "CN51AN1KN00W2000",
////      "CN51,Num,EA30 Std Img,802.11 abgn,BT,WEH 6.5",
////      "/Date(1553880607317)/"
////    ],
////    ["Asset Number",
////      "044X1700270",
////      "CN51AN1KN00W2000",
////      "CN51,Num,EA30 Std Img,802.11 abgn,BT,WEH 6.5",
////      "/Date(1553880607320)/"
////    ],
////    ["Asset Number",
////      "044X1700273",
////      "CN51AN1KN00W2000",
////      "CN51,Num,EA30 Std Img,802.11 abgn,BT,WEH 6.5",
////      "/Date(1553880607323)/"
////    ],
////    ["Asset Number",
////      "044X1700402",
////      "CN51AN1KN00W2000",
////      "CN51,Num,EA30 Std Img,802.11 abgn,BT,WEH 6.5",
////      "/Date(1553880607323)/"
////    ],
////    ["Asset Number",
////      "068X1700376",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607330)/"
////    ],
////    ["Asset Number",
////      "068X1700377",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607330)/"
////    ],
////    ["Asset Number",
////      "068X1700381",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607333)/"
////    ],
////    ["Asset Number",
////      "068X1700386",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607333)/"
////    ],
////    ["Asset Number",
////      "068X1700389",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607337)/"
////    ],
////    ["Asset Number",
////      "068X1700392",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607340)/"
////    ],
////    ["Asset Number",
////      "068X1700398",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607343)/"
////    ],
////    ["Asset Number",
////      "068X1700401",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607347)/"
////    ],
////    ["Asset Number",
////      "068X1700418",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607347)/"
////    ],
////    ["Asset Number",
////      "068X1700419",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607350)/"
////    ],
////    ["Asset Number",
////      "068X1700421",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607353)/"
////    ],
////    ["Asset Number",
////      "068X1700426",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607357)/"
////    ],
////    ["Asset Number",
////      "068X1700427",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607360)/"
////    ],
////    ["Asset Number",
////      "068X1700428",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607360)/"
////    ],
////    ["Asset Number",
////      "068X1700430",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607363)/"
////    ],
////    ["Asset Number",
////      "068X1700432",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607367)/"
////    ],
////    ["Asset Number",
////      "068X1700438",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607370)/"
////    ],
////    ["Asset Number",
////      "068X1700439",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607370)/"
////    ],
////    ["Asset Number",
////      "068X1700441",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607373)/"
////    ],
////    ["Asset Number",
////      "068X1700445",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607377)/"
////    ],
////    ["Asset Number",
////      "068X1700447",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607380)/"
////    ],
////    ["Asset Number",
////      "068X1700454",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607380)/"
////    ],
////    ["Asset Number",
////      "068X1700461",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607383)/"
////    ],
////    ["Asset Number",
////      "068X1700477",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607383)/"
////    ],
////    ["Asset Number",
////      "068X1700478",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607407)/"
////    ],
////    ["Asset Number",
////      "068X1700480",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607410)/"
////    ],
////    ["Asset Number",
////      "068X1700486",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607410)/"
////    ],
////    ["Asset Number",
////      "068X1700488",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607413)/"
////    ],
////    ["Asset Number",
////      "068X1700492",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607417)/"
////    ],
////    ["Asset Number",
////      "068X1700495",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607420)/"
////    ],
////    ["Asset Number",
////      "068X1700503",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607420)/"
////    ],
////    ["Asset Number",
////      "068X1700509",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607423)/"
////    ],
////    ["Asset Number",
////      "068X1700513",
////      "CN51AN1KC00A1000",
////      "CN51,NUM,EA30,2D,IMG,CAM,WLAN",
////      "/Date(1553880607427)/"
////    ]
////  ];

////  getAdvancedHeaders() {
////    return [
////      {
////        name: "Asset Reference",
////        sort: null,
////      },
////      {
////        name: "Serial Number",
////        sort: 0,
////      },
////      {
////        name: "Part Number",
////        sort: 0,
////      },
////      {
////        name: "Description",
////        sort: null,
////      },
////      {
////        name: "Location",
////        sort: 0,
////      },
////      {
////        name: "Customer",
////        sort: 0,
////      },
////      {
////        name: "Contract No",
////        sort: 0,
////      },
////      {
////        name: "Warranty Expires",
////        sort: 0,
////      },
////      {
////        name: "Contract Expires",
////        sort: 0,
////      },
////    ];
////  }

////  getAdvancedTableNumOfPage(countPerPage) {
////    return Math.ceil(this.advanceTableData.length / countPerPage);
////  }

////  getAdvancedTablePage(page, countPerPage) {
////    return this.advanceTableData.slice((page - 1) * countPerPage, page * countPerPage);
////  }

////  changeAdvanceSorting(order, index) {
////    this.advanceTableData = this.sorting(this.advanceTableData, order, index);
////  }

////  private sorting(array, order, value) {
////    const compareFunction = (a, b) => {
////      if (a[value] > b[value]) {
////        return 1 * order;
////      }
////      if (a[value] < b[value]) {
////        return -1 * order;
////      }
////      return 0;
////    };
////    return array.sort(compareFunction);
////  }
////}


import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { User } from "../models"
import { BehaviorSubject } from "rxjs";
import { map, } from "rxjs/operators";
import { AuthenticationService } from "../services"
@Injectable({ providedIn: "root" })
export class AssetsService {

  private baseUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  areas: string[];
  access: string;
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string, private readonly authService: AuthenticationService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
    this.areas = this.currentUserSubject.value.areas;
    this.access = this.currentUserSubject.value.access;
    this.baseUrl = baseUrl;
  }
  getAssets(contractNo: string): Observable<AssetData[]> {
    return this.http.get<GetAssetsResponse>(`${this.baseUrl}api/Assets/GetAssets?contractNo=` + contractNo)
      .pipe(map<GetAssetsResponse, AssetData[]>(response => {
        return response.assetData;
      }));
  }
    
  getAssetDetails(serialNo: string): Observable<GetAssetDetailsResponse> {
    return this.http.get<GetAssetDetailsResponse>(`${this.baseUrl}api/Assets/AssetDetails?serialNo=` + serialNo)
      .pipe(map<GetAssetDetailsResponse, GetAssetDetailsResponse>(response => {
        return response;
      }));
  }

  isAdmin() {
    //return this.roles.includes("AssetAdmin");
    return this.access === "Site" || this.access === "Company";
  }

}


export class GetAssetsResponse {
  error: string;
  assetData: AssetData[];
}


export class GetAssetDetailsResponse {
  error: string;
  assetDetails: AssetDetails;
}

export class AssetData {
  productNo: string;
  description: string;
  serialNo: string;
  customer: string;
  contractNo: string;
  warrantyExpires: Date;
  contractExpires: Date;
}

export class AssetDetails {
  SerialNo: string;
  ProductNo: string;
  Description: string;
  Customer: string;
  CustomerAddress1: string;
  CustomerAddress2: string;
  CustomerAddress3: string;
  CustomerTown: string;
  CustomerCounty: string;
  CustomerPostcode: string;
  Category: string;
  AssetNo: string;
  Location: string;
  ContractExpiry: Date;
}

//export class TicketHistory {
//  description: string;
//  actionDate: Date;
//  actionUserName: string;
//  notes: string;
//  isCustomerAction: boolean;
//  actionUserEmail: string;
//}
