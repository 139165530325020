import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders, HttpEvent } from '@angular/common/http';
import { User } from "../models"

import { map, } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class LocationsService {
  private baseUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  areas: string[];
  access: string;

  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string) {
    this.baseUrl = baseUrl;
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
    this.areas = this.currentUserSubject.value.areas;
    this.access = this.currentUserSubject.value.access;
  }


  getLocations() {
    return this.http.get<GetLocationsResponse>(`${this.baseUrl}api/Locations/GetLocationsList`).pipe(map(response => {
      return response;
    }));
  }

  isAdmin() {
    return this.access === "Company";
  }


}

export class GetLocationsResponse {
  locationsData: LocationsData;
  error: string;
}

export class LocationsData {
  assignedLocation: LocationData;
  locations: LocationData[];
}

export class LocationData {
  id: number;
  description: string;

  name: string;
  address1: string;
  address2: string;
  address3: string;
  town: string;
  county: string;
  postCode: string;
}
