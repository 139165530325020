import { Component } from "@angular/core";

import { SidebarComponent as BaseSidebarComponent } from "theme/components/sidebar";

import { AuthenticationService } from "../../services/"

@Component({
  selector: "app-sidebar",
  styleUrls: ["../../../theme/components/sidebar/sidebar.component.scss"],
  templateUrl: "../../../theme/components/sidebar/sidebar.component.html",
})
export class SidebarComponent extends BaseSidebarComponent {
  title = "Renovotec Hub";
  menu = [
  
  ];

  constructor(private authService: AuthenticationService) {
    super();

    if (authService.currentUserValue === null) {
      return;
    }

    const areas = authService.currentUserValue.areas;
    const access = authService.currentUserValue.access;

    if (!areas) {
      return;
    }
    if (!access) {
      return;
    }

    //if (areas.includes("Tickets")) {
    //  this.menu.push(({
    //    name: "Tickets",
    //    icon: "view_list",
    //    children: [
    //      { name: "Open Tickets", link: "/app/tickets" },
    //      { name: "Closed Tickets", link: "/app/tickets/closed" },
    //      { name: "Create New", link: "/app/tickets/new" }
    //    ]
    //  }) as any); 
    //}

    if (areas.includes("Store") && (access.includes("Company") || access.includes("Site"))) {
      this.menu.push({
        name: "Store",
        icon: "local_grocery_store",
        children: [
          { name: "Browse", link: "/app/store" },
          { name: "Basket", link: "/app/store/basket" },
          { name: "Quotes", link: "/app/quotes" },
          { name: "Orders", link: "/app/orders" },
        ]
      });
    }


  }
}

