import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { User } from "../models"
import { BehaviorSubject } from "rxjs";
import { map, } from "rxjs/operators";
import { AuthenticationService } from "../services"
@Injectable({ providedIn: "root" })
export class ContractsService {

  private baseUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  areas: string[];
  access: string;
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string, private readonly authService: AuthenticationService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem("currentUser")));
    this.areas = this.currentUserSubject.value.areas;
    this.access = this.currentUserSubject.value.access;
    this.baseUrl = baseUrl;
  }
  getContracts(): Observable<ContractData[]> {
    return this.http.get<GetContractsResponse>(`${this.baseUrl}api/Contracts/GetContracts`)
      .pipe(map<GetContractsResponse, ContractData[]>(response => {
        return response.contractData;
      }));
  }  

  isAdmin() {
    //return this.areas.includes("ContractAdmin");
    return this.access === "Site" || this.access === "Company";
  }

}


export class GetContractsResponse {
  error: string;
  contractData: ContractData[];
}


export class ContractData {
  productNo: string;
  description: string;
  serialNo: string;
  customer: string;
  contractNo: string;
  warrantyExpires: Date;
  contractExpires: Date;
}
