import { Component, Inject, OnInit } from '@angular/core';
import { BlankLayoutCardComponent } from "app/components/blank-layout-card";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { AlertService, AuthenticationService } from "../../services";

@Component({
  selector: 'splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})

export class SplashComponent extends BlankLayoutCardComponent {

  code: string;
  loading: boolean = false;
  private readonly baseUrl: string;

  public constructor(
    @Inject("BASE_URL") baseUrl: string,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public dialog: MatDialog) {

    super();
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }

    this.baseUrl = baseUrl;
  }

  ngOnInit() {
    this.code = this.route.snapshot.queryParams["code"] || null;
    if (this.code != null) {
      this.sendAuthToken();
    }
    else {
      this.navigateToLogin();
    }
  }

  sendAuthToken() {
    // If the property is not an empty string then call authentication service to get the auth token.
    if (this.code == null) {
      return;
    }

    this.authenticationService.getAsgardeoAuthToken(this.code).subscribe(
      data => {
        if (data.success === true) {
          this.router.navigate(["app/store"]);
          //this.router.navigate([this.returnUrl]);
        } else {
          this.navigateToErrorPage();
        }
        this.loading = false;
      },
      error => {
        this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
        this.loading = false;
      });
  }

  navigateToLogin() {
    const asgardeoLoginUrl = "https://api.eu.asgardeo.io/t/renovotec/oauth2/authorize?response_type=code&client_id=9P1SJRsEueTLrtpFcN4P3TXr68wa&scope=openid%20profile&redirect_uri=" + this.baseUrl + "splash";
    document.location.href = asgardeoLoginUrl;
  }

  navigateToErrorPage() {
    this.router.navigate(["error"]);
  }
}
