import { NgModule } from "@angular/core";
import { LinkDirective } from "../helpers";

@NgModule({
  imports: [
  ],
  declarations: [
    LinkDirective
  ],
  entryComponents: [

  ],
  exports: [
    LinkDirective
    ]
})
export class HelpersModule { }
