import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { LayoutsModule } from "../../layouts";
//import { NewTicketComponent } from "./new-ticket";
//import { ClosedTicketsComponent } from "./closed-tickets/closed-tickets.component";
import { AssetDetailsComponent } from "./details";

import { CommonLayoutComponent } from "../../layouts/common-layout";



@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "",
        component: CommonLayoutComponent,
        children: [
          //{ path: "app/tickets/new", component: NewTicketComponent, pathMatch: "full" },
          //{ path: "app/tickets/closed", component: ClosedTicketsComponent, pathMatch: "full" },
          { path: "app/assets/details/:id", component: AssetDetailsComponent, pathMatch: "full" },
        ],
      },
    ]),
    LayoutsModule,
  ],
  exports: [RouterModule],
})
export class AssetsRoutingModule { }
