import { Component, ViewChild, HostBinding, AfterViewInit } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort, SortDirection } from "@angular/material/sort";
import { TicketsService, TicketData } from "../../../services";
import { Router } from "@angular/router";
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: "closed-tickets-selector",
  templateUrl: "closed-tickets.component.html",
  styleUrls: ["./closed-tickets.component.scss"],

})
export class ClosedTicketsComponent extends UpgradableComponent implements AfterViewInit {
  @HostBinding("class.mdl-grid") private readonly mdlGrid = true;
  @HostBinding("class.mdl-cell") private readonly mdlCell = true;
  @HostBinding("class.mdl-cell--12-col-desktop") private readonly mdlCell12ColDesktop = true;
  @HostBinding("class.mdl-cell--12-col-tablet") private readonly mdlCell12ColTablet = true;
  @HostBinding("class.mdl-cell--4-col-phone") private readonly mdlCell4ColPhone = true;
  @HostBinding("class.mdl-cell--top") private readonly mdlCellTop = true;
  @HostBinding("class.ui-tables") private readonly uiTables = true;

  displayedColumns: string[] = ["ticketNumber", "subjectLine", "openDate", "closedDate"];

  loading = false;
  noTickets = false;

  // MatPaginator Inputs
  length = 1000;
  pageSize = 15;
  pageSizeOptions: number[] = [5, 10, 15, 50, 100];

  public ticketNoFilter: string = "";
  public subjectFilter: string = "";
  public callerFilter: string = "";
  public locationFilter: string = "";

  private currentPageSize = 15;
  private currentPageIndex = 0;
  private currentSort = "CloseDate";
  private currentSortDirection = null;

  closedResponseDataSource: TicketData[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // MatPaginator Output
  pageEvent: PageEvent;

  public constructor(private readonly tablesService: TicketsService,
    private readonly router: Router) {
    super();
    this.loading = true;
    if (this.tablesService.isAdmin()) {
      this.displayedColumns = ["ticketNumber", "subjectLine", "openDate", "caller", "location", "status", "closedDate"];
    }

    this.updateTableData();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  onPageChanged(e) {
    this.currentPageIndex = e.pageIndex;
    this.currentPageSize = e.pageSize;
    this.updateTableData();
  }

  onMatSortChange(sort: Sort) {

    this.currentSort = sort.active;
    this.currentSortDirection = sort.direction;
    this.paginator.firstPage(); //need to reset the table to page 1 not just our variable
    this.currentPageIndex = 0;
    this.updateTableData();
  }

  search() {
    this.paginator.firstPage(); //need to reset the table to page 1 not just our variable
    this.currentPageIndex = 0;
    this.updateTableData();
  }

  updateTableData() {
    this.loading = true;
    this.noTickets = false;
    this.tablesService.getClosedTicketsPaged(this.currentSort, this.currentSortDirection, this.currentPageIndex, this.currentPageSize,
      this.ticketNoFilter, this.subjectFilter, this.callerFilter, this.locationFilter).subscribe(result => {
      if (result.ticketData.length === 0) {
        this.noTickets = true;
      }

      this.closedResponseDataSource = result.ticketData;
      this.length = result.totalCount
      this.loading = false;
    });
  }
   
  getDetails(row: TicketData) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`app/tickets/details/` + row.ticketNumber])
    );

    window.open(url, "_blank");
  }
}
