import { Component, ViewChild, HostBinding } from "@angular/core";
import { UpgradableComponent } from "theme/components/upgradable";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute, Router } from "@angular/router";
import { AssetData, AssetsService } from "../../services";
import { DatePipe } from '@angular/common';


@Component({
  selector: "assets",
  templateUrl: "assets.component.html",
  styleUrls: ["assets.component.scss"],
  providers: [DatePipe]
})
export class AssetsComponent extends UpgradableComponent {
  @HostBinding("class.mdl-grid") private readonly mdlGrid = true;
  @HostBinding("class.mdl-cell") private readonly mdlCell = true;
  @HostBinding("class.mdl-cell--12-col-desktop") private readonly mdlCell12ColDesktop = true;
  @HostBinding("class.mdl-cell--12-col-tablet") private readonly mdlCell12ColTablet = true;
  @HostBinding("class.mdl-cell--4-col-phone") private readonly mdlCell4ColPhone = true;
  @HostBinding("class.mdl-cell--top") private readonly mdlCellTop = true;
  @HostBinding("class.ui-tables") private readonly uiTables = true;

  displayedColumns: string[] = ["productNo", "description", "serialNo", "customer", "contractNo", "warrantyExpires", "contractExpires"];
  openResponseDataSource = new MatTableDataSource<AssetData>();

  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    this.openResponseDataSource.paginator = matPaginator;
  }
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.openResponseDataSource.sort = matSort;
  }

  loading = false;
  noTickets = false;

  public constructor(private readonly tablesService: AssetsService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe  ) {
    super();
    this.loading = true;
    if (this.tablesService.isAdmin()) {
      this.displayedColumns = ["productNo", "description", "serialNo", "customer", "contractNo", "warrantyExpires", "contractExpires"];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.openResponseDataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit() {
    const id = this.activatedRoute.snapshot.paramMap.get("id");
    this.tablesService.getAssets(id).subscribe(result => {
      if (result.length === 0) {
        this.noTickets = true;
      }
      this.openResponseDataSource = new MatTableDataSource<AssetData>(result);
      this.loading = false;
    });
  }


  getDetails(row: AssetData) {
    this.router.navigate(["app/assets/details/" + row.serialNo]);
  }

  getFileName() {
    const dateTime = new Date();
    const dateString = this.datePipe.transform(dateTime, 'yyyyMMdd_HHmmss');
    return "Asset_Export_" + dateString;
  }
}
