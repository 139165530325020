import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ThemeModule } from "theme";
import { TicketsComponent } from "./tickets.component"
import { NewTicketComponent } from "./new-ticket"
import { OpenTicketsComponent } from "./open-tickets/open-tickets.component"
import { AwaitingTicketsComponent } from "./awaiting-tickets/awaiting-tickets.component"
import { ClosedTicketsComponent } from "./closed-tickets/closed-tickets.component"
import { TicketDetailsComponent  } from "./details/ticket-details.component"
import { AddAttachmentComponent } from "./add-attachment/add-attachment.component"
import { AddNoteComponent } from "./add-note/add-note.component"

import { TicketsRoutingModule } from "./tickets-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "./../../material-module"
import { MatSortModule } from '@angular/material';

import {HelpersModule} from "./../../helpers"
@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    TicketsRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HelpersModule,
    MatSortModule
  ],
  declarations: [
    NewTicketComponent,
    TicketsComponent,
    OpenTicketsComponent,
    AwaitingTicketsComponent,
    ClosedTicketsComponent,
    TicketDetailsComponent,
    AddAttachmentComponent,
    AddNoteComponent

  ],
  entryComponents: [
    AddAttachmentComponent,
    AddNoteComponent

  ]
})
export class TicketsModule { }
