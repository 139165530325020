import { Component } from "@angular/core";

import { BlankLayoutCardComponent } from "app/components/blank-layout-card";
import { first } from "rxjs/operators";

import { AlertService, AuthenticationService } from "../../../services";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";


@Component({
  selector: "app-confirm-account",
  styleUrls: ["../../../components/blank-layout-card/blank-layout-card.component.scss"],
  templateUrl: "./confirm-account.component.html",
})
export class ConfirmAccountComponent extends BlankLayoutCardComponent {

  submitted = false;
  confirmKey: string;
  username: string;
  loading = false;



  public constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.confirmKey = this.route.snapshot.queryParamMap.get("confirmationKey");
    if (this.confirmKey == null) {
      this.router.navigate([""]);
    }
  }



  onSubmit() {
    this.submitted = true;

    this.loading = true;


    this.authenticationService.confirmAccount(this.confirmKey)
      .pipe(first())
      .subscribe(
        data => {
          if (data.success === true) {
            this.alertService.error("Account has been activated!");
            this.router.navigate(["login"]);
          } else {
            this.alertService.error(data.error);
          }
          this.loading = false;

        },
        error => {
          this.alertService.error("Oops! Something went wrong, please try again later.  If the problem persists please contact helpdesk.");
          this.loading = true;

        });
  }
}
